<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

withDefaults(
  defineProps<{
    text?: string;
  }>(),
  { text: 'Loading...' },
);

const a11ySpinnerTime = ref<number>(0);
const spinnerTimeout = ref<number | null>(null);

onMounted(() => {
  spinnerTimeout.value = setInterval(() => {
    a11ySpinnerTime.value++;
  }, 1000);
});

onBeforeUnmount(() => {
  if (spinnerTimeout.value) {
    clearTimeout(spinnerTimeout.value);
  }
});
</script>

<template>
  <div class="polling__container">
    <div aria-hidden="true">
      <div class="loader" />
    </div>
    <p class="polling__text">
      {{ text }}
    </p>
    <p
      aria-label="Configuration Timer"
      aria-roledescription="Waiting for question set configuration from server"
      role="timer"
      aria-live="off"
      >{{ a11ySpinnerTime }}</p
    >
  </div>
</template>

<style scoped lang="scss">
.polling {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 380px);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__text {
    @include format-text-style(echo, regular);

    display: block;
    margin: 0 auto;
    text-align: center;
  }
}

[role='timer'] {
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.loader {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 32px auto;
  color: var(--accent-color);
  background-color: var(--accent-color);
  border-radius: var(--border-radius-50);
  transform: scale(1.25, 1.25);
  animation: dotStretching 2s infinite ease-in;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    display: inline-block;
    content: '';
  }

  &::before {
    width: 32px;
    height: 32px;
    color: var(--accent-color);
    background-color: var(--accent-color);
    border-radius: var(--border-radius-50);
    animation: dotStretchingBefore 2s infinite ease-in;
  }

  &::after {
    width: 32px;
    height: 32px;
    color: var(--accent-color);
    background-color: var(--accent-color);
    border-radius: var(--border-radius-50);
    animation: dotStretchingAfter 2s infinite ease-in;
  }

  @keyframes dotStretching {
    0% {
      transform: scale(1.25, 1.25);
    }

    50%,
    60% {
      transform: scale(0.8, 0.8);
    }

    100% {
      transform: scale(1.25, 1.25);
    }
  }

  @keyframes dotStretchingBefore {
    0% {
      transform: translate(0) scale(0.7, 0.7);
    }

    50%,
    60% {
      transform: translate(-42px) scale(1, 1);
    }

    100% {
      transform: translate(0) scale(0.7, 0.7);
    }
  }

  @keyframes dotStretchingAfter {
    0% {
      transform: translate(0) scale(0.7, 0.7);
    }

    50%,
    60% {
      transform: translate(42px) scale(1, 1);
    }

    100% {
      transform: translate(0) scale(0.7, 0.7);
    }
  }
}
</style>
