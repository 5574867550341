import '@design/index.scss';

import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { LazyLoadDirective } from './directives/lazyLoad';

// @ts-expect-error plugin error
createApp(App).use(router).directive('lazy', LazyLoadDirective).mount('#app');
