import { createRouter, createWebHistory } from 'vue-router';
import type { Router } from 'vue-router';

import routes from './routes';

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (to.hash) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resolve({ selector: to.hash });
        } else if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ top: 0, left: 0 });
        }
      }, 100);
    });
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.title = routeTo.meta.title;

  return next();
});

export default router;
