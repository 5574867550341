import type { Directive } from 'vue';

export const LazyLoadDirective: Directive = {
  mounted(el) {
    const imageObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target as HTMLImageElement;
          if (lazyImage.dataset.src) {
            lazyImage.src = lazyImage.dataset.src;
            imageObserver.unobserve(el);
          }
        }
      }
    });
    imageObserver.observe(el);
  },
};
