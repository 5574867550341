const siteTitle = 'Lucinda Griffith Design Consultancy';

export default [
  {
    path: '/',
    name: 'home',
    component: (): Promise<unknown> => import(/* webpackChunkName: "home" */ './views/HomeView.vue'),
    meta: {
      title: `Home - ${siteTitle}`,
      transition: 'fade',
      label: 'Home',
      step: true,
    },
  },
  {
    path: '/consultancy/',
    name: 'about',
    component: (): Promise<unknown>  => import(/* webpackChunkName: "about" */'./views/AboutView.vue'),
    meta: {
      title: `Consultancy - ${siteTitle}`,
      transition: 'fade',
      label: 'Consultancy',
      step: true,
    },
  },
  {
    path: '/gallery/',
    name: 'gallery',
    component: (): Promise<unknown>  => import(/* webpackChunkName: "gallery" */'./views/GalleryView.vue'),
    meta: {
      title: `Gallery - ${siteTitle}`,
      transition: 'fade',
      label: 'Gallery',
      step: true,
    },
  },
  {
    path: '/press/',
    name: 'press',
    component: (): Promise<unknown>  => import(/* webpackChunkName: "press" */'./views/PressView.vue'),
    meta: {
      title: `Press - ${siteTitle}`,
      transition: 'fade',
      label: 'Press',
      step: true,
    },
  },
  {
    path: '/testimonials/',
    name: 'Testimonials',
    component: (): Promise<unknown>  => import(/* webpackChunkName: "testimonials" */'./views/TestimonialsView.vue'),
    meta: {
      title: `Testimonials - ${siteTitle}`,
      transition: 'fade',
      label: 'Testimonials',
      step: true,
    },
  },
  {
    path: '/contact/',
    name: 'contact',
    component: (): Promise<unknown> => import(/* webpackChunkName: "contact" */'./views/ContactView.vue'),
    meta: {
      title: `Contact - ${siteTitle}`,
      transition: 'fade',
      label: 'Contact',
      step: true,
    },
  },
  {
    path: '/404/',
    name: 'notFound',
    component: (): Promise<unknown> => import(/* webpackChunkName: "error" */'./views/_404.vue'),
    props: true,
    meta: {
      title: `(404) Page not found | ${siteTitle}`,
      transition: 'fade',
      step: false,
    },
  },
  {
    path: '/:catchAll(.*)/',
    redirect: { name: 'notFound' },
  },
];
